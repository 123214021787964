/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IsoResponseBody } from '../models/IsoResponseBody';
import type { PricesRequestBody } from '../models/PricesRequestBody';
import type { PricesResponseBody } from '../models/PricesResponseBody';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PricingService {

    /**
     * @param requestBody
     * @returns PricesResponseBody OK
     * @throws ApiError
     */
    public static getRoamingPrices(
        requestBody: PricesRequestBody,
    ): CancelablePromise<PricesResponseBody> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/roamingprices',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param country
     * @returns IsoResponseBody OK
     * @throws ApiError
     */
    public static getFilteredIsoCodes(
        country: string,
    ): CancelablePromise<IsoResponseBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/isocodesfilter/{country}',
            path: {
                'country': country,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns IsoResponseBody OK
     * @throws ApiError
     */
    public static getIsoCodes(): CancelablePromise<IsoResponseBody> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/isocodes',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

}